import React, { useState, useEffect, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import axios from "axios";

// Dynamic imports for your components with React.lazy
const Signin = React.lazy(() => import("./components/Signin"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const NotFound = React.lazy(() => import("./components/Notfound"));
const Download = React.lazy(() => import("./components/Download"));

// ProtectedRoute component remains as is
const ProtectedRoute = ({ isAuthenticated, isAuthChecked, children }) => {
  if (!isAuthChecked) return null;
  return isAuthenticated ? children : <Navigate to="/signin" replace />;
};

function App() {
  // State for checking Authentication
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/users/isAuthenticated`,
          {
            withCredentials: true,
          }
        );
        setIsAuthenticated(response.data.isAuthenticated);
        setIsAuthChecked(true);
      } catch (error) {
        console.error("Error checking authentication status", error);
        setIsAuthChecked(true);
      }
    };

    checkAuth();
  }, []);

  // Handler for updating Authentication state
  const handleAuthentication = (authState) => setIsAuthenticated(authState);

  // Routes definition using useRoutes hook, dynamically loaded components are used here
  const routes = useRoutes([
    {
      path: "/signin",
      element: <Signin onSignInSuccess={handleAuthentication} />,
    },
    {
      path: "/",
      element: <NotFound />,
    },
    {
      path: "/download",
      element: <Download />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          isAuthChecked={isAuthChecked}
        >
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/download" replace />,
    },
  ]);

  // Render the routes wrapped in Suspense to handle dynamic import loading
  return <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>;
}

export default App;
